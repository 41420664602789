<template>
    <div>
        <div class="d-flex flex-column-fluid">
            <div class="container ">
                <div class="card-header  p-0 pt-0 border-0 bg-none"></div>
                <div class="card card-custom">
                    <div class="card-body" id="kt_content">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th>Year</th>
                                    <th>Status</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, i) in yearList" :key="i">
                                    <td>{{ item.year }}</td>
                                    <td>
                                        <span v-if="item.status == 1" >Active</span>
                                        <span v-else>Inactive</span>
                                    </td>
                                    <td>
                                        <a href="Javascript:void(0)" v-if="item.status == 0" @click="activatePrompt(item)">
                                            <span class="svg-icon  svg-icon-primary svg-icon-md">
                                                <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                                                    <g stroke="none" stroke-width="1">
                                                        <rect x="14" y="9" width="6" height="6" rx="3" fill="black"/>
                                                        <rect x="3" y="9" width="6" height="6" rx="3" fill="black" fill-opacity="0.7"/>
                                                    </g>
                                                </svg>
                                            </span>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
   <ConfirmDialog></ConfirmDialog>
</template>
<script>
import { defineComponent, ref, watch, onMounted, provide   , inject } from 'vue'
import ConfirmDialog from 'primevue/confirmdialog'
import { useConfirm } from "primevue/useconfirm"
import  YearActivate  from '@/composables/year/YearActivate'
import  YearGetList  from '@/composables/year/YearGetList'


export default defineComponent({
    components: {
        ConfirmDialog
    },
    setup() {
        const { http, store, constants, toast } = inject('App')
        const confirm = useConfirm()
        
        const { activateStatus, activateYear } = YearActivate(http, constants, toast)
        const { yearList, getList } = YearGetList(http, constants, store)

        
        function activatePrompt(year) {
            confirm.require({
                message: `Are you sure you want to activate this year.`,
                header: 'Ooops!',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'Continue Activate',
                accept: async () => {
                    await activateYear(year.year)
                    getList()
                },
                reject: () => {
                }
            })
            return {activatePrompt}
        }

        onMounted(async() => {
            getList()
        })

        return {
            activatePrompt,
            yearList
        }
    },
})
</script>
