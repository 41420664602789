import { ref } from 'vue'
export default function YearActivate(http, constant, toast) {
    const activateStatus = ref(false)
    async function activateYear(year) {
        try {
            activateStatus.value = true
            const f = new URLSearchParams()
            f.append('year', year)
            const response = await http.post(constant.YEAR_ACTIVATE, f)
            toast.add({severity:'success', summary: 'Success', detail:'Year was successfully activated.', life: 3000});

            activateStatus.value = false
        } catch (err) {
            activateStatus.value = false
        }
    }
    return {
        activateStatus,
        activateYear
    }
}